/* eslint-disable no-unused-vars */
import { Navigate } from 'react-router-dom';
import { DYNAMIC_FORM_MODE } from '@servicexcelerator/ui-design-system';
import Login from './pages/CustomerAdvisor/Login';
import Dashboard from './pages/CustomerAdvisor/Dashboard';
import ServiceJobDetail from './pages/CustomerAdvisor/ServiceJobDetail';
import Chat from './pages/CustomerAdvisor/Chat';
import ServiceJobFormPage from './pages/ServiceJob';
import ServiceJobsSearch from './pages/ServiceJobs/search';
import ServiceJobScheduling from './pages/ServiceJobScheduling';
import CreateServiceJob from './pages/CreateServiceJob';
import DemoSetupPage from './pages/DemoSetup';
import ServiceJobAppointment from './pages/ServiceJobAppointment';

const routes = ({ ProtectedRoute, BubbleRouteError, AppModule }) => [
  {
    path: 'servicejob',
    children: [
      {
        path: '',
        element: <Navigate to="/dashboard" />,
        errorElement: <BubbleRouteError />,
      },
      {
        path: 'demosetup',
        element: (
          <ProtectedRoute
            componentProps={{
              formMode: DYNAMIC_FORM_MODE.CREATE,
              AppModule,
            }}
            Component={DemoSetupPage}
          />
        ),
        errorElement: <BubbleRouteError />,
      },
      {
        path: 'maps/:serviceJobId',
        element: (
          <ProtectedRoute
            componentProps={{
              formMode: DYNAMIC_FORM_MODE.CREATE,
              AppModule,
            }}
            Component={ServiceJobScheduling}
          />
        ),
        errorElement: <BubbleRouteError />,
      },
      {
        path: 'appointment/:serviceJobNumber',
        element: (
          <ProtectedRoute
            componentProps={{
              AppModule,
            }}
            Component={ServiceJobAppointment}
          />
        ),
        errorElement: <BubbleRouteError />,
      },
      {
        path: 'create/selectcustomer',
        element: (
          <ProtectedRoute
            componentProps={{
              formMode: DYNAMIC_FORM_MODE.CREATE,
              AppModule,
            }}
            Component={CreateServiceJob}
          />
        ),
        errorElement: <BubbleRouteError />,
      },
      {
        path: 'create/:serviceJobRefId',
        element: (
          <ProtectedRoute
            componentProps={{
              formMode: DYNAMIC_FORM_MODE.CREATE,
              AppModule,
            }}
            Component={ServiceJobFormPage}
          />
        ),
        errorElement: <BubbleRouteError />,
      },
      {
        path: 'view/:serviceJobId',
        element: (
          <ProtectedRoute
            componentProps={{ formMode: DYNAMIC_FORM_MODE.VIEW, AppModule }}
            Component={ServiceJobFormPage}
          />
        ),
        errorElement: <BubbleRouteError />,
      },
      {
        path: 'edit/:serviceJobId/:serviceJobRefId?',
        element: (
          <ProtectedRoute
            componentProps={{ formMode: DYNAMIC_FORM_MODE.EDIT, AppModule }}
            Component={ServiceJobFormPage}
          />
        ),
        errorElement: <BubbleRouteError />,
      },
      {
        path: '*',
        element: <Navigate to="/dashboard" />,
        errorElement: <BubbleRouteError />,
      },
    ],
  },
  {
    path: 'servicejobs',
    children: [
      {
        path: '',
        element: <Navigate to="/servicejobs/search" />,
        errorElement: <BubbleRouteError />,
      },
      {
        path: 'search',
        element: (
          <ProtectedRoute
            componentProps={{ AppModule }}
            Component={ServiceJobsSearch}
          />
        ),
        errorElement: <BubbleRouteError />,
      },
      {
        path: '*',
        element: <Navigate to="/servicejobs/search" />,
        errorElement: <BubbleRouteError />,
      },
    ],
  },
  {
    path: 'customer-advisor',
    children: [
      {
        path: '',
        element: <Login />,
        errorElement: <BubbleRouteError />,
      },
      {
        path: 'dashboard',
        element: (
          <ProtectedRoute
            componentProps={{
              formMode: DYNAMIC_FORM_MODE.CREATE,
              AppModule,
            }}
            Component={Dashboard}
          />
        ),
        errorElement: <BubbleRouteError />,
      },
      {
        path: 'servicejob/:serviceJobId',
        element: <ServiceJobDetail AppModule={AppModule} />,
        errorElement: <BubbleRouteError />,
      },
      {
        path: 'chat',
        element: <Chat />,
        errorElement: <BubbleRouteError />,
      },
      {
        path: '*',
        element: <Navigate to="customer-advisor" />,
        errorElement: <BubbleRouteError />,
      },
    ],
  },
];

export default routes;
