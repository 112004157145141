import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { Logo, LOGO_TYPE } from '@servicexcelerator/ui-design-system';
import { useState } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';

/*
 Not happy with technician not come.
 Happy coming on time.
*/

function MenuIconText({ Icon, children }) {
  return (
    <>
      <Icon sx={{ fontSize: '16px', marginRight: '4px' }} color="primary" />
      {children}
    </>
  );
}

function MobileHeader({
  AppModule,
  toggleDrawer = () => {},
  showChat = false,
}) {
  const { Icons, Utils } = AppModule;
  const auth = useAuth();
  const { Home, Chat, Logout } = Icons;
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const { GetDomain } = Utils;

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = async () => {
    const CUSTOMER_ADVISOR_DASHBAORD = `${GetDomain(
      process.env.REACT_APP_API_HOST,
      '',
      false,
    )}/customer-advisor/?actor=serviceprovider`;
    await auth.signoutRedirect({
      post_logout_redirect_uri: CUSTOMER_ADVISOR_DASHBAORD,
    });
  };

  return (
    <Box sx={{ marginBottom: '20px' }}>
      <AppBar
        position="static"
        sx={{ background: 'transparent' }}
        elevation={0}>
        <Toolbar>
          <Logo size="small" type={LOGO_TYPE.FULL} />
          <Box sx={{ flexGrow: 1 }}>&nbsp;</Box>
          <div>
            <IconButton size="large" onClick={handleMenu} color="primary">
              <MenuIcon />
            </IconButton>
            <Menu
              MenuListProps={{ dense: true }}
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}>
              <MenuItem onClick={() => navigate('/customer-advisor/dashboard')}>
                <MenuIconText Icon={Home}>Dashboard</MenuIconText>
              </MenuItem>
              {showChat && (
                <MenuItem
                  onClick={() => {
                    toggleDrawer(true);
                    handleClose(true);
                  }}>
                  <MenuIconText Icon={Chat}>Chat</MenuIconText>
                </MenuItem>
              )}
              <MenuItem onClick={() => logout()}>
                <MenuIconText Icon={Logout}>Logout</MenuIconText>
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default MobileHeader;
