import { FormattedMessage, useIntl } from 'react-intl';
import { Box, FormLabel } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import {
  FormValue,
  HeaderTitle,
  useStore,
} from '@servicexcelerator/ui-design-system';

function PageHeader({ pageData = null, AppModule }) {
  const { Utils, Icons } = AppModule;
  const { Claim } = Icons;
  const { toLongDate, MasterDataUtil } = Utils;
  const store = useStore();

  const { formatMessage } = useIntl();
  const masterData = store.domain.masterData.get();

  const saClientName = MasterDataUtil.getSAClientById(
    masterData,
    pageData?.serviceAdministratorClientNumber,
  )?.label;

  return (
    <Grid2
      container
      alignItems="center"
      spacing={4}
      sx={{ justifyContent: { xs: 'center', sm: 'start' } }}>
      <Grid2 sx={{ display: { xs: 'none', sm: 'block' } }}>
        <HeaderTitle Icon={Claim}>
          <FormattedMessage id="SERVICEJOB" defaultMessage="Job" />
        </HeaderTitle>
      </Grid2>
      {pageData?.serviceJobNumber && (
        <Grid2>
          <Box>
            <FormLabel>
              {formatMessage({
                id: 'SERVICEJOB_NUMBER',
                defaultMessage: 'Service Job Number',
              })}
            </FormLabel>
          </Box>
          <Box>
            <FormValue>{pageData?.serviceJobNumber}</FormValue>
          </Box>
          {pageData?.serviceJobStatusDate && (
            <Box>
              <FormLabel>
                {toLongDate(pageData?.serviceJobStatusDate)}
              </FormLabel>
            </Box>
          )}
        </Grid2>
      )}
      {pageData?.serviceAdministratorClientNumber && (
        <Grid2>
          <Box>
            <FormLabel>
              {formatMessage({
                id: 'CLIENT',
                defaultMessage: 'Client',
              })}
            </FormLabel>
          </Box>
          <Box>
            <FormValue>{saClientName}</FormValue>
          </Box>
        </Grid2>
      )}
      {pageData?.serviceJobStatus?.value && (
        <Grid2>
          <Box>
            <FormLabel>
              {formatMessage({
                id: 'STATUS',
                defaultMessage: 'Status',
              })}
            </FormLabel>
          </Box>
          <Box>
            <FormValue>{pageData?.serviceJobStatus?.value}</FormValue>
          </Box>
        </Grid2>
      )}
    </Grid2>
  );
}
export default PageHeader;
