import SearchClaimForms from './pages/ClaimForms/search';

const routes = ({ ProtectedRoute, BubbleRouteError, AppModule }) => [
  {
    path: 'claimforms',
    children: [
      {
        path: 'search',
        element: (
          <ProtectedRoute
            componentProps={{ AppModule }}
            Component={SearchClaimForms}
          />
        ),
        errorElement: <BubbleRouteError />,
      },
    ],
  },
];

export default routes;
