import { createElement } from 'react';
import { nanoid } from 'nanoid';
// eslint-disable-next-line import/no-cycle
import getComponents from './getComponents';
import { DYNAMIC_FORM_MODE } from './constants';
import FormJsonSchemaUtil from './jsonSchemaUtil';

function renderComponents({
  setValue = () => undefined,
  getValues = () => undefined,
  uiSchema,
  control,
  jsonSchema,
  additionalComponents = {},
  formMode = DYNAMIC_FORM_MODE.VIEW,
  masterData = [],
  formId,
  effectFunctions = null,
  formatMessage = undefined,
  itemsArrayIndex = null,
}) {
  /*
   * Handling Component and Component props
   * Each Component Config has 3 props
   *  {
   *    component:(React Node)} [Required]
   *    componentProps: { customprop, ...componentProps} [Required]
   *    container:(React Node)} [Optional]
   *  }
   */
  let Component = null;
  let isRequired = false;
  let type = null;
  let format = null;
  let dataType = 'string';
  const componentConfigKey = uiSchema['ui:component'] || 'TextField';

  const {
    containerProps = null,
    hasContainer = true,
    hideifNoValue = false,
    ...componentProps
  } = uiSchema['ui:props'] || {
    containerProps: null,
  };

  let hasConstantValue = false;

  const nameAttribute = uiSchema['jsonschema:name']
    ? FormJsonSchemaUtil.getHtmlNameFromSchemaName({
        jsonSchemaName: uiSchema['jsonschema:name'],
        itemsArrayIndex,
      })
    : null;

  if (uiSchema['jsonschema:name']) {
    const currentObjectName = uiSchema['jsonschema:name'].split('.').pop();
    const jsonObject = FormJsonSchemaUtil.getAttributeFromSchema(
      jsonSchema,
      uiSchema['jsonschema:name'],
    );
    if (jsonObject?.const) {
      hasConstantValue = true;
    }

    if (jsonObject?.format) {
      format = jsonObject?.format;
    }

    if (jsonObject?.type) {
      dataType = jsonObject?.type;
    } else if (componentProps?.dataType) {
      dataType = componentProps?.dataType;
    }

    if (componentConfigKey === 'TextField' && jsonObject?.type === 'number') {
      type = 'number';
    }

    const parent = uiSchema['jsonschema:name']
      .split('.')
      .slice(0, -2)
      .join('.');
    const parentObject = FormJsonSchemaUtil.getAttributeFromSchema(
      jsonSchema,
      parent,
    );
    if (parentObject?.required) {
      isRequired = !!parentObject?.required.includes(currentObjectName);
    }
  }

  const commonProps = {
    setValue,
    getValues,
    control,
    hasConstantValue,
    formMode,
    ...(type && { type }),
    ...(dataType && { dataType }),
    required: isRequired,
    masterData,
    formId,
    jsonSchema,
    format,
    effectFunctions,
    formatMessage,
    itemsArrayIndex,
    additionalComponents,
    renderComponents,
  };

  const hideComponent =
    nameAttribute &&
    hideifNoValue &&
    (getValues(nameAttribute) === '' ||
      getValues(nameAttribute) === null ||
      getValues(nameAttribute) === undefined);

  if (hideComponent) {
    return null;
  }

  if (componentConfigKey === 'CustomControl') {
    return (
      additionalComponents[uiSchema['ui:props'].additionalComponentKey]({
        ...commonProps,
        ...componentProps,
        ...(nameAttribute && { name: nameAttribute }),
      }) || null
    );
  }

  let components = getComponents({
    componentProps,
    additionalComponents,
    renderComponents,
    ...commonProps,
  });

  components = {
    ...components,
    additionalComponents,
  };

  /*
   * Handling Children and Children Container props
   */
  let children = null;
  const hasChildren = uiSchema['ui:children']?.length;

  if (hasChildren) {
    children = uiSchema['ui:children'].map(item =>
      renderComponents({
        setValue,
        getValues,
        uiSchema: item,
        control,
        jsonSchema,
        additionalComponents,
        formMode,
        masterData,
        formId,
        effectFunctions,
        formatMessage,
        itemsArrayIndex,
      }),
    );
  }

  Component = createElement(
    components[componentConfigKey].component,
    {
      key: nanoid(),
      ...(nameAttribute && { name: nameAttribute }),
      ...components[componentConfigKey].componentProps,
    },
    children,
  );

  if (
    !hideComponent &&
    hasContainer &&
    components[componentConfigKey].componentProps?.containerComponent
  ) {
    const ContainerComponent =
      components[componentConfigKey].componentProps?.containerComponent;
    return (
      <ContainerComponent key={nanoid()} sm xs={12} {...containerProps}>
        {Component}
      </ContainerComponent>
    );
  }

  return Component;
}

export default renderComponents;
