import { Box, Card, CardContent, Divider, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';

function ConductCategories({ aiResponse }) {
  const getIntentCategoryColor = score => {
    if (score < 0) {
      return 'red';
    }
    return 'green';
  };

  return (
    <Box>
      <Card elevation={0} variant="outlined">
        <CardContent>
          <Typography color="primary" variant="h6">
            Conduct Analysis
          </Typography>
          <Divider sx={{ mb: 2 }} />
          {aiResponse?.intent_categories.map(category => (
            <Grid2 container sx={{ pb: 1 }} spacing={1}>
              <Grid2 xs="auto">
                {category?.score < 0 ? (
                  <ClearIcon
                    fontSize="14px"
                    sx={{ color: getIntentCategoryColor(category?.score) }}
                  />
                ) : (
                  <DoneIcon
                    fontSize="14px"
                    sx={{ color: getIntentCategoryColor(category?.score) }}
                  />
                )}
              </Grid2>
              <Grid2 xs>
                <Typography
                  variant="subtitle2"
                  component="div"
                  color={getIntentCategoryColor(category?.score)}>
                  {category.category}
                </Typography>
              </Grid2>
            </Grid2>
          ))}
        </CardContent>
      </Card>
    </Box>
  );
}

export default ConductCategories;
