import { Box, Card, CardContent, Chip, Typography } from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';

function UpSideDownSide({ side = 'upside', count = 0 }) {
  return (
    <Card elevation={0} variant="outlined">
      <CardContent textAlign="center" sx={{ p: 1 }}>
        <Typography sx={{ fontSize: '40px' }} textAlign="center">
          {count}
        </Typography>
        <Box mt={1} textAlign="center">
          <Chip
            color={side === 'downside' ? 'error' : 'success'}
            variant="outlined"
            icon={side === 'downside' ? <ThumbDownIcon /> : <ThumbUpIcon />}
            label={side === 'downside' ? 'Downside' : 'Upside'}
          />
        </Box>
      </CardContent>
    </Card>
  );
}

export default UpSideDownSide;
