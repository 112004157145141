import { Box, Card, CardContent, Typography } from '@mui/material';
import SmsFailedIcon from '@mui/icons-material/SmsFailed';
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';
import Grid2 from '@mui/material/Unstable_Grid2';

function AIOverallReport({ aiResponse }) {
  const getIntentColor = intent => {
    if (intent === 'Negative') {
      return '#ff0000';
    }
    return 'green';
  };
  return (
    <Card elevation={0}>
      <CardContent>
        <Grid2 container>
          <Grid2 xs>
            <Typography
              textAlign="left"
              sx={{
                color: `${getIntentColor(aiResponse?.overall_intent)} `,
                mt: 1,
                fontSize: '30px',
              }}>
              {aiResponse.overall_intent}
            </Typography>
            <Typography
              sx={{ fontSize: '14px', fontWeight: 'bold' }}
              textAlign="left">
              Quality of Conversation
            </Typography>
          </Grid2>
          <Grid2 xs>
            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
              <Box
                sx={{
                  width: '60px',
                  height: '60px',
                  backgroundColor:
                    aiResponse?.overall_intent === 'Negative'
                      ? '#C63C51'
                      : 'green',
                  mt: 2,
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '10px',
                  display: 'flex',
                }}>
                {aiResponse?.overall_intent !== 'Negative' ? (
                  <MarkChatReadIcon
                    sx={{ fontSize: '30px', color: '#ffffff' }}
                  />
                ) : (
                  <SmsFailedIcon sx={{ fontSize: '30px', color: '#ffffff' }} />
                )}
              </Box>
            </Box>
          </Grid2>
        </Grid2>
      </CardContent>
    </Card>
  );
}

export default AIOverallReport;
