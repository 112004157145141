import { Box, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import {
  DYNAMIC_FORM_MODE,
  DynamicForm,
  LoadingEvent,
  ScrollingProvider,
  SpecialSection,
  StackedFloatingButtons,
  useDataProvider,
  useStore,
} from '@servicexcelerator/ui-design-system';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import getFormSchemas from '../ServiceJob/lib/getFormSchemas';
import getAdditionalComponents from '../ServiceJob/lib/getAdditionalComponents';
import MobileHeader from './MobileHeader';
import PageHeader from '../ServiceJob/Header/Header';
import Chat from './Chat';

function getServiceJobIdFromRoute({ formMode, routeParam }) {
  if (
    formMode === DYNAMIC_FORM_MODE.EDIT ||
    formMode === DYNAMIC_FORM_MODE.VIEW
  ) {
    return routeParam?.serviceJobId;
  }
  return routeParam?.serviceJobRefId;
}

function ServiceJobDetail({ formMode = DYNAMIC_FORM_MODE.VIEW, AppModule }) {
  const { Components, Constants } = AppModule;
  const { useUserAccess } = Components;
  const { COMPANY_TYPE } = Constants;

  const provider =
    process.env.NODE_ENV === 'development' ? 'serviceJob' : 'default';

  const routeParam = useParams();
  const { getOne } = useDataProvider(provider);
  const store = useStore();
  const { formatMessage } = useIntl();
  const formRef = useRef();

  const userAccess = useUserAccess();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [serviceJobNumber, setServiceJobNumber] = useState(null);
  const [pageError, setPageError] = useState(null);
  const [serviceJobData, setServiceJobData] = useState({});
  const [formSchemas, setFormSchemas] = useState({});
  const [mode, setMode] = useState(formMode);
  const [errorBlock, setErrorBlock] = useState([]);

  const toggleDrawer = arg => {
    setOpen(arg);
  };

  const masterData = store.domain.masterData.get();

  const isSA = userAccess.companyType === COMPANY_TYPE.SERVICE_ADMINISTRATOR;

  const fetchServiceJobData = async serviceJobNumberArg => {
    if (formMode === DYNAMIC_FORM_MODE.CREATE) {
      // eslint-disable-next-line no-unused-vars
      return store.domain.serviceJobs.getProspectiveServiceJob(
        serviceJobNumberArg,
      );
    }
    const result = await getOne(
      `servicejob/v1/servicejob/${serviceJobNumberArg}`,
    );
    return result?.data;
  };

  // TODO: Fix this, effect should be per form, have global effect functions and then apply them based on form
  const effectFunctions = () => ({
    handlePaymentApprovedFields: true,
    handlePAREditMode: false,
    readOnly: true,
    showAddButtonForParts: true,
    isNotSA: true,
  });

  const updateFormSchema = schema => {
    if (schema?.ui) {
      // eslint-disable-next-line no-param-reassign
      schema.ui['ui:children'] = schema?.ui['ui:children'].map(
        (child, index) => ({
          ...child,
          'ui:props': {
            ...child['ui:props'],
            defaultExpanded: index === 0,
          },
        }),
      );
    }
    return schema;
  };

  useEffect(() => {
    async function init() {
      setErrorBlock(null);
      const serviceJobIdFromRoute = getServiceJobIdFromRoute({
        formMode,
        routeParam,
      });
      if (!serviceJobIdFromRoute) {
        setPageError({
          errorMessage: formatMessage({
            id: 'SERVICEJOB_NUMBER_NOT_PROVIDED',
            defaultMessage: 'Job not provided',
          }),
        });
      } else {
        setLoading(true);
        setServiceJobNumber(serviceJobIdFromRoute);

        const serviceJobDataFound = await fetchServiceJobData(
          serviceJobIdFromRoute,
          formMode,
        );

        if (serviceJobDataFound) {
          setServiceJobData({
            ...serviceJobDataFound,
            ...(mode === DYNAMIC_FORM_MODE.CREATE && {}),
          });

          const formSchemasByServiceJobFormId = getFormSchemas(
            serviceJobDataFound?.serviceJobFormId,
            isSA,
            serviceJobDataFound?.serviceJobStatus.key,
          );

          setFormSchemas(updateFormSchema(formSchemasByServiceJobFormId));
          if (
            ['ACC', 'CAN'].includes(serviceJobDataFound.serviceJobStatus.key)
          ) {
            setMode(DYNAMIC_FORM_MODE.VIEW);
          } else {
            setMode(formMode);
          }
        }
        setLoading(false);
      }
    }

    init();
  }, [formMode, routeParam?.serviceJobRefId, routeParam?.serviceJobId]);

  const validSchema =
    formSchemas?.ui &&
    formSchemas?.ajv[DYNAMIC_FORM_MODE.CREATE === mode ? 'create' : 'update'];

  return (
    <Box>
      <Chat
        serviceJobData={serviceJobData}
        open={open}
        serviceJobNumber={serviceJobNumber}
        toggleDrawer={toggleDrawer}
        AppModule={AppModule}
      />
      <MobileHeader
        AppModule={AppModule}
        getServiceJobId={() => serviceJobNumber}
        toggleDrawer={arg => toggleDrawer(arg)}
        showChat
      />
      <PageHeader AppModule={AppModule} pageData={serviceJobData} />
      <ScrollingProvider top={100}>
        <Box sx={{ m: 1 }}>
          {loading && (
            <LoadingEvent mt={4}>
              <FormattedMessage
                id="PROCESSING"
                defaultMessage="Processing..."
              />
            </LoadingEvent>
          )}
          {!loading && !pageError && (
            <Grid2>
              {errorBlock}

              {validSchema ? (
                <DynamicForm
                  key={serviceJobNumber}
                  ignoreUnSaved={false}
                  formId={serviceJobNumber}
                  uiSchema={formSchemas.ui}
                  jsonSchema={
                    formSchemas.ajv[
                      DYNAMIC_FORM_MODE.CREATE === mode ? 'create' : 'update'
                    ]
                  }
                  onSubmitSuccess={() => undefined}
                  onSubmitError={() => undefined}
                  reference={formRef}
                  additionalComponents={getAdditionalComponents(
                    serviceJobData,
                    mode,
                    AppModule,
                  )}
                  initialFormValues={serviceJobData}
                  formMode={mode}
                  masterData={masterData}
                  initialized={!loading}
                  loadingComponent={<LoadingEvent>Loading Schema</LoadingEvent>}
                  errors={[]}
                  effectFunctions={effectFunctions()}
                  unsavedChangeMessage={formatMessage({
                    id: 'UNSAVED_CHANGES_MESSAGE',
                    defaultMessage:
                      'There are some unsaved changes in the form. Do you want to discard all the changes?',
                  })}
                  formatMessage={formatMessage}
                  memoParams={[serviceJobData?.serviceJobNumber, mode]}
                />
              ) : (
                <SpecialSection
                  style={{ marginTop: '100px' }}
                  color="error.main">
                  <Typography mt={3} mb={3} color="error.main">
                    <FormattedMessage
                      id="FORM_SCHEMA_NOT_FOUND"
                      defaultMessage="Error: No schema information found to show the form"
                    />
                  </Typography>
                </SpecialSection>
              )}

              <StackedFloatingButtons
                position={{ bottom: true, right: true }}
                buttons={[]}
              />
            </Grid2>
          )}
        </Box>
      </ScrollingProvider>
    </Box>
  );
}

export default observer(ServiceJobDetail);
