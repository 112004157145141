import { DYNAMIC_FORM_MODE } from '@servicexcelerator/ui-design-system';
import RulesEditor from './pages/RulesEditor/index';
import RulesSearch from './pages/Rules/search';

const routes = ({ ProtectedRoute, BubbleRouteError, AppModule }) => [
  {
    path: 'rules',
    children: [
      {
        path: 'edit/:ruleId',
        element: (
          <ProtectedRoute
            componentProps={{ formMode: DYNAMIC_FORM_MODE.EDIT, AppModule }}
            Component={RulesEditor}
          />
        ),
        errorElement: <BubbleRouteError />,
      },
      {
        path: 'create',
        element: (
          <ProtectedRoute
            componentProps={{ formMode: DYNAMIC_FORM_MODE.CREATE, AppModule }}
            Component={RulesEditor}
          />
        ),
        errorElement: <BubbleRouteError />,
      },
      {
        path: 'search',
        element: (
          <ProtectedRoute
            componentProps={{ AppModule }}
            Component={RulesSearch}
          />
        ),
        errorElement: <BubbleRouteError />,
      },
    ],
  },
];

export default routes;
