import { Box, Divider, FormLabel } from '@mui/material';
import QRCode from 'react-qr-code';

function QRCodes({ serviceJobData }) {
  const customerPhoneNumber =
    serviceJobData?.customer?.customerPhoneManaged.phone || 'ERROR';
  const spPhoneNumber =
    serviceJobData?.assignedServiceProvider?.managedPhoneNumber || 'ERROR';
  return (
    <Box sx={{ padding: '10px 10px' }}>
      <Box>
        <FormLabel sx={{ display: 'block', marginBottom: '15px' }}>
          Customer ({customerPhoneNumber})
        </FormLabel>
        <QRCode
          value={`SMSTO:${customerPhoneNumber}:Service Xcelerator Registration for Customer`}
        />
      </Box>

      <Box>
        <FormLabel
          sx={{ display: 'block', marginBottom: '15px', marginTop: '30px' }}>
          Service Provider ({spPhoneNumber})
        </FormLabel>
        <QRCode
          value={`SMSTO:${spPhoneNumber}:Service Xcelerator Registration for Service Provider`}
        />
      </Box>
      <Box sx={{ display: 'block', marginBottom: '15px', marginTop: '30px' }}>
        <Divider />
      </Box>
    </Box>
  );
}

export default QRCodes;
