import { Box, Typography, styled } from '@mui/material';
import IconChip from '../IconChip';

export const Container = styled(Box)(() => ({
  display: 'inline-flex',
  alignItems: 'center',
}));

function HeaderTitle({ Icon, children, IconProps, ...props }) {
  return (
    <Container {...props}>
      {Icon && (
        <IconChip
          bgColor="primary.main"
          iconColor="#ffffff"
          Icon={Icon}
          size={50}
          {...IconProps}
        />
      )}
      <Typography variant="h5" sx={{ fontWeight: 500, marginLeft: '10px' }}>
        {children}
      </Typography>
    </Container>
  );
}

export default HeaderTitle;
