import { Box, Button, Divider, Typography } from '@mui/material';
import {
  ConfirmationPopUp,
  LoadingEvent,
  Section,
  useDataProvider,
  useSnackbar,
} from '@servicexcelerator/ui-design-system';

import { useEffect, useRef, useState } from 'react';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';
import MobileHeader from './MobileHeader';
import completedServiceJob from '../ServiceJob/lib/completedServiceJob';

function Dashboard({ AppModule }) {
  const snackbar = useSnackbar();
  const { formatMessage } = useIntl();
  const { Icons, Components, Utils } = AppModule;
  const { ServiceJob } = Icons;
  const { ServiceJobStatusIcon } = Components;
  const { toLongDate, ErrorMessage } = Utils;
  const confirmationPopUpRef = useRef();
  const provider =
    process.env.NODE_ENV === 'development' ? 'serviceJob' : 'default';
  const { getMany, getApiUrl, updateOne } = useDataProvider(provider);

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);

  async function fetchData() {
    if (!data.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    const apiUrl = new URL(
      `${getApiUrl()}/servicejob/v1/servicejob/alljobs?limit=200&page=1`,
    );

    try {
      const response = await getMany(apiUrl.href);
      if (response?.data) {
        const responseData = response?.data;
        setData(responseData?.data?.reverse());
      }
    } catch (error) {
      // Log error
    }
    setIsLoading(false);
    setIsRefetching(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box>
      <MobileHeader AppModule={AppModule} />
      <Box>
        <Grid2 m={1} xs={12}>
          <Section headerIcon={ServiceJob} label="Service Jobs">
            <Grid2 container direction="column" spacing={1} mt={2}>
              {data.map(job => (
                <Grid2 container key={job.serviceJobNumber} spacing={1}>
                  <Grid2 xs={6}>
                    <Link
                      to={`/customer-advisor/servicejob/${job.serviceJobNumber}`}>
                      {job.serviceJobNumber}
                    </Link>
                  </Grid2>
                  <Grid2 xs={6} textAlign="right">
                    {job.customer.customerNameFirst}{' '}
                    {job.customer.customerNameLast}
                  </Grid2>
                  <Grid2 xs={6}>
                    <Grid2 sx={{ display: 'inline-flex' }} alignItems="center">
                      <ServiceJobStatusIcon
                        statusId={job.serviceJobStatus.key}
                        sx={{ marginRight: '5px', fontSize: '16px' }}
                      />
                      <Typography variant="subtitle2">
                        {job.serviceJobStatus.value}
                      </Typography>
                    </Grid2>
                  </Grid2>
                  <Grid2 xs={6} textAlign="right">
                    <Typography variant="subtitle2">
                      {toLongDate(job?.serviceCreatedDate)}
                    </Typography>
                    <Box mt={1}>
                      {['ACC', 'PAC'].includes(job.serviceJobStatus.key) && (
                        <Button
                          size="small"
                          variant="contained"
                          onClick={() => {
                            completedServiceJob(
                              job,
                              confirmationPopUpRef,
                              formatMessage,
                              snackbar,
                              updateOne,
                              ErrorMessage,
                            );
                          }}>
                          Mark Completed
                        </Button>
                      )}
                    </Box>
                  </Grid2>
                  <Grid2 xs={12}>
                    <Divider />
                  </Grid2>
                </Grid2>
              ))}
            </Grid2>
          </Section>
        </Grid2>
      </Box>
      <ConfirmationPopUp reference={confirmationPopUpRef} />
      {(isLoading || isRefetching) && (
        <Box>
          <LoadingEvent />
        </Box>
      )}
    </Box>
  );
}

export default Dashboard;
