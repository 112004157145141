import { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import AILoader from './AiLoader';
import AIOverallReport from './aiReportWidgets/AIOverallReport';
import UpSideDownSide from './aiReportWidgets/UpsideDownSide';
import ConductCategories from './aiReportWidgets/ConductCategories';
import Summary from './aiReportWidgets/Summary';

const AI_AGENT_NAME = 'Luke';

// const TEST_AI_RESPONSE = {
//   result: {
//     overall_intent: 'Negative',
//     intent_categories: [
//       {
//         category: 'Unprofessional Behavior',
//         score: 8,
//       },
//       {
//         category: 'Multiple Reschedules/Delay in Scheduling',
//         score: 8,
//       },
//     ],
//     summary:
//       "The customer reached out expressing frustration over the technician's behavior, describing them as rude. This clearly left the customer feeling disrespected and dissatisfied with the service experience. Furthermore, the issue was compounded by the technician failing to arrive at the scheduled time, adding to the customer's inconvenience and frustration. Unfortunately, the response from the service provider seemed dismissive, which likely exacerbated the customer's negative sentiment. The lack of an adequate apology or resolution for both the unprofessional behavior and the scheduling issue suggests a need for the service provider to improve their approach to customer care and service reliability.",
//   },
// };

function Luke({ message = '', bold = false }) {
  return (
    <Typography
      sx={{
        mb: 5,
        fontWeight: bold ? 'bold' : 'normal !important',
        color: 'black',
      }}>
      <Typography
        component="span"
        sx={{ fontWeight: 'bold', fontStyle: 'italic' }}
        color="primary">
        {AI_AGENT_NAME}
      </Typography>{' '}
      {message}
    </Typography>
  );
}

function AiChatAnalysis({ conversations }) {
  const [aiResponse, setAiResponse] = useState(null);
  const [error, setError] = useState(false);

  const convertChatForAI = conversationsArg =>
    conversationsArg
      .map(chat => {
        if (chat.user.type === 'CUSTOMER') {
          return `CU:${chat.message}.  `;
        }
        if (chat.user.type === 'SERVICE_PROVIDER') {
          return `SP:${chat.message}.  `;
        }
        return '';
      })
      .join('');

  const fetchAiResponse = async chatData => {
    try {
      const response = await fetch('/api/summarize', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ conversation_text: chatData }),
      });
      const data = await response.json();
      setAiResponse(data.result);
      setError(false);
    } catch (errorMessage) {
      // eslint-disable-next-line no-console
      console.error('Error fetching AI response:', errorMessage.message);
      setAiResponse(null);
      setError(true);
    }
    // TODO: Test
    // setAiResponse(TEST_AI_RESPONSE.result);
    // setError(false);
    // console.log(chatData);
  };

  const convertAndSetAiResponse = () => {
    setError(false);
    const aiInput = convertChatForAI(conversations);
    fetchAiResponse(aiInput);
  };

  const getCountBySide = side =>
    aiResponse.intent_categories.filter(category =>
      side === 'upside' ? category.score >= 0 : category.score < 0,
    ).length || 0;

  useEffect(() => {
    if (conversations.length > 1) {
      convertAndSetAiResponse();
    }
  }, []);

  if (error) {
    return (
      <Box sx={{ mt: 7, textAlign: 'center' }}>
        <Luke
          message="had some issue processing the request. Ask him to give it
          another try."
          bold={false}
        />
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            convertAndSetAiResponse();
          }}>
          Retry
        </Button>
      </Box>
    );
  }

  if (!aiResponse) {
    return (
      <Box sx={{ mt: 7, textAlign: 'center' }}>
        <Luke message="is analyzing" bold />
        <AILoader />
      </Box>
    );
  }

  return (
    <Box>
      <Box>
        <AIOverallReport aiResponse={aiResponse} />
      </Box>
      <Box mt={2}>
        <Grid2 container spacing={2}>
          <Grid2 xs>
            <UpSideDownSide side="upside" count={getCountBySide('upside')} />
          </Grid2>
          <Grid2 xs>
            <UpSideDownSide
              side="downside"
              count={getCountBySide('downside')}
            />
          </Grid2>
        </Grid2>
      </Box>
      <Box mt={2}>
        <ConductCategories aiResponse={aiResponse} />
      </Box>
      <Box mt={2}>
        <Summary aiResponse={aiResponse} />
      </Box>
    </Box>
  );
}

export default AiChatAnalysis;
