import { useState } from 'react';
import { Box, Divider, Drawer, IconButton, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import CloseIcon from '@mui/icons-material/Close';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import TwilioConversationFeed from '../../ui/TwilioFeed/conversation';
import QRCodes from './QRCodes';
import AiChatAnalysis from './AiChatAnalysis';

function CustomerAdvisorChat({
  serviceJobData,
  open,
  serviceJobNumber,
  toggleDrawer,
  AppModule,
}) {
  const [showAiConversation, setShowAiConversation] = useState(false);
  const [showQRCodes, setShowQRCodes] = useState(false);
  const [conversations, setConversations] = useState([]);

  const SHOW_QR_CODE = false;

  return (
    <Drawer
      PaperProps={{
        sx: {
          width: '95%',
        },
      }}
      open={open}>
      {serviceJobData && (
        <Box>
          <Grid2 container>
            <Grid2 xs={7}>
              <Typography
                color="primary"
                variant="h6"
                sx={{ mt: '8px', ml: '10px', mb: '8px' }}>
                {serviceJobNumber}
              </Typography>
            </Grid2>
            <Grid2 xs={3} textAlign="right">
              {SHOW_QR_CODE && (
                <IconButton onClick={() => setShowQRCodes(!showQRCodes)}>
                  <QrCode2Icon color="primary" />
                </IconButton>
              )}
              {conversations?.length > 0 && (
                <IconButton
                  onClick={() => setShowAiConversation(!showAiConversation)}>
                  <AutoAwesomeIcon
                    {...(showAiConversation && { color: 'primary' })}
                  />
                </IconButton>
              )}
            </Grid2>
            <Grid2 xs={2}>
              <Box textAlign="right">
                <IconButton
                  sx={{
                    color: '#000000',
                  }}
                  onClick={() => {
                    setShowAiConversation(false);
                    toggleDrawer(false);
                  }}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Box>
            </Grid2>
          </Grid2>
          <Box>
            <Divider />
          </Box>
          <Box sx={{ padding: '10px 10px' }}>
            {showQRCodes && <QRCodes serviceJobData={serviceJobData} />}
            {showAiConversation && (
              <AiChatAnalysis conversations={conversations} />
            )}
            {!showAiConversation && (
              <TwilioConversationFeed
                serviceJobData={serviceJobData}
                AppModule={AppModule}
                onChange={changeData => {
                  setConversations(changeData.conversations || []);
                }}
                isMobile
              />
            )}
          </Box>
        </Box>
      )}
    </Drawer>
  );
}

export default CustomerAdvisorChat;
