import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import {
  LoadingEvent,
  useDataProvider,
} from '@servicexcelerator/ui-design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { Avatar, Box, Card, Typography, styled, useTheme } from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { useEffect, useState } from 'react';
import Sentry from '../../pages/CustomerAdvisor/Sentry';

const SHOW_SENTRY_AI_CONVERSATION = false;

const ChatCard = styled(Card)({
  borderRadius: '15px',
  minWidth: {
    xs: '98%',
    sm: '30%',
  },
  maxWidth: {
    xs: '98%',
    sm: '45%',
  },
  display: 'inline-block',
  padding: '10px',
});

function ConversationAI({ message }) {
  const theme = useTheme();
  return (
    <Box mt={1} mb={1} textAlign="center">
      <ChatCard
        sx={{
          padding: '6px 10px',
          border: `1px solid ${theme.palette.warning.main}`,
        }}
        variant="outlined">
        <Box>
          <Grid2 container spacing={1}>
            <Grid2 xs="auto">
              <AutoAwesomeIcon color="warning" fontSize="12px" />
            </Grid2>
            <Grid2 xs>
              <Typography
                sx={{ fontWeight: 400, color: theme.palette.warning.main }}
                variant="body1">
                {message}
              </Typography>
            </Grid2>
          </Grid2>
        </Box>
      </ChatCard>
    </Box>
  );
}

function ConversationRight({ message, createdTime, AppModule }) {
  const { Utils } = AppModule;
  const { toLongDate } = Utils;
  return (
    <Box mt={1} mb={1} textAlign="right">
      <Grid2 container>
        <Grid2 xs>
          <ChatCard variant="outlined">
            <Box textAlign="left">
              <Typography sx={{ fontWeight: 400 }} variant="body1">
                {message}
              </Typography>
            </Box>
            <Box>
              <Typography variant="caption">
                {toLongDate(createdTime)}
              </Typography>
            </Box>
          </ChatCard>
        </Grid2>
        <Grid2 alignSelf="center">
          <Avatar sx={{ marginLeft: '8px' }}>SP</Avatar>
        </Grid2>
      </Grid2>
    </Box>
  );
}

function ConversationLeft({ message, createdTime, AppModule }) {
  const { Utils } = AppModule;
  const { toLongDate } = Utils;
  const theme = useTheme();
  return (
    <Box mt={1} mb={1} textAlign="left">
      <Grid2 container>
        <Grid2 alignSelf="center">
          <Avatar
            sx={{
              marginRight: '8px',
              bgcolor: theme.palette.primary.main,
            }}>
            CX
          </Avatar>
        </Grid2>
        <Grid2 xs>
          <ChatCard variant="outlined">
            <Box textAlign="left">
              <Typography sx={{ fontWeight: 400 }} variant="body1">
                {message}
              </Typography>
            </Box>
            <Box>
              <Typography textAlign="right" variant="caption">
                {toLongDate(createdTime)}
              </Typography>
            </Box>
          </ChatCard>
        </Grid2>
      </Grid2>
    </Box>
  );
}

function Conversation({ message, userType, createdTime, AppModule }) {
  if (userType === 'SERVICE_PROVIDER') {
    return (
      <ConversationRight
        message={message}
        userType={userType}
        createdTime={createdTime}
        AppModule={AppModule}
      />
    );
  }
  if (userType === 'AI' && SHOW_SENTRY_AI_CONVERSATION) {
    return (
      <ConversationAI
        message={message}
        userType={userType}
        createdTime={createdTime}
        AppModule={AppModule}
      />
    );
  }
  if (userType === 'CUSTOMER') {
    return (
      <ConversationLeft
        message={message}
        userType={userType}
        createdTime={createdTime}
        AppModule={AppModule}
      />
    );
  }
  return null;
}

function Conversations({ conversations, defaultMessage, AppModule }) {
  if (Array.isArray(conversations) && conversations.length === 0) {
    return <Typography>{defaultMessage}</Typography>;
  }

  return conversations.map(c => (
    <Conversation
      key={c?.createdTime}
      message={c?.message}
      userType={c?.user?.type}
      status={c?.status}
      createdTime={c?.createdTime}
      direction={c?.direction}
      AppModule={AppModule}
    />
  ));
}

function TwilioConversationFeed({
  serviceJobData,
  AppModule,
  onChange = () => undefined,
  isMobile = false,
}) {
  const { formatMessage } = useIntl();
  const defaultMessage = formatMessage({
    id: 'NO_DATA_EXISTS',
    defaultMessage: 'No data exists',
  });

  const provider =
    process.env.NODE_ENV === 'development' ? 'serviceJob' : 'default';
  const { getOne } = useDataProvider(provider);

  const [loading, setLoading] = useState(true);
  const [conversationData, setConversationData] = useState([]);

  const formatPhoneNumber = phoneNumber =>
    phoneNumber
      ? encodeURIComponent(`+1${phoneNumber.replace(/[\s-]/g, '')}`)
      : '';

  const setConversationDataWithAi = async data => {
    if (SHOW_SENTRY_AI_CONVERSATION) {
      const processedData = Sentry.processConversation(data);
      onChange({
        conversationCount: data.length,
        conversations: processedData?.data || [],
      });
      setConversationData(processedData?.data || []);
    } else {
      onChange({
        conversationCount: data.length,
        conversations: data || [],
      });
      setConversationData(data);
    }
  };

  // const testData = [
  //   {
  //     message: 'Hello, are you sure it is not working?',
  //     user: { type: 'SERVICE_PROVIDER' },
  //     status: 'sent',
  //     createdTime: '2023-10-01T10:01:00Z',
  //     direction: 'outbound',
  //   },
  //   {
  //     message: 'The technician didn’t seem to know what they were doing',
  //     user: { type: 'CUSTOMER' },
  //     status: 'sent',
  //     createdTime: '2023-10-01T10:02:00Z',
  //     direction: 'inbound',
  //   },
  //   {
  //     message: 'Duh! Maybe you dont know how to operate it, are you sure?',
  //     user: { type: 'SERVICE_PROVIDER' },
  //     status: 'sent',
  //     createdTime: '2023-10-01T10:01:00Z',
  //     direction: 'outbound',
  //   },
  //   {
  //     message: 'The technician was rude and unprofessional.',
  //     user: { type: 'CUSTOMER' },
  //     status: 'received',
  //     createdTime: '2023-10-01T10:04:00Z',
  //     direction: 'inbound',
  //   },
  //   {
  //     message: 'I might be an hour late for our appointment',
  //     user: { type: 'SERVICE_PROVIDER' },
  //     status: 'sent',
  //     createdTime: '2023-10-01T10:01:00Z',
  //     direction: 'outbound',
  //   },
  //   {
  //     message: 'The technician didn’t arrive at the scheduled time',
  //     user: { type: 'CUSTOMER' },
  //     status: 'received',
  //     createdTime: '2023-10-01T10:04:00Z',
  //     direction: 'inbound',
  //   },
  // ];

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      if (serviceJobData?.customer?.customerPhoneManaged?.phone) {
        const spNumber =
          serviceJobData?.assignedServiceProvider?.managedPhoneNumber;

        const customerManagePhoneNumber = formatPhoneNumber(
          serviceJobData?.customer?.customerPhoneManaged?.phone,
        );
        const serviceProviderManagePhoneNumber = formatPhoneNumber(
          serviceJobData?.assignedServiceProvider?.managedPhoneNumber,
        );

        const spParam = spNumber
          ? `&serviceProviderManagePhoneNumber=${serviceProviderManagePhoneNumber}`
          : '';

        const result = await getOne(
          `/notifications/v1/conversation?serviceJobNumber=${serviceJobData?.serviceJobNumber}&customerManagePhoneNumber=${customerManagePhoneNumber}${spParam}&sendAfter=${serviceJobData?.serviceCreatedDate}`,
        );
        if (result) {
          const newData = isMobile
            ? result?.data?.conversation?.data.reverse()
            : result?.data?.conversation?.data;
          setConversationDataWithAi(newData || []);
        }
      }
      // Todo: remove after testing
      // setConversationDataWithAi(testData);

      setLoading(false);
    };

    if (serviceJobData?.serviceJobNumber) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, [serviceJobData?.serviceJobNumber]);

  return (
    <Grid2>
      {loading ? (
        <LoadingEvent>
          <FormattedMessage id="FETCHING_DATA" defaultMessage="Fetching Data" />
        </LoadingEvent>
      ) : (
        <Conversations
          conversations={conversationData}
          defaultMessage={defaultMessage}
          AppModule={AppModule}
        />
      )}
    </Grid2>
  );
}

export default TwilioConversationFeed;
