import { Card, CardContent, Divider, Typography } from '@mui/material';

function Summary({ aiResponse }) {
  return (
    <Card elevation={0} variant="outlined">
      <CardContent>
        <Typography color="primary" variant="h6">
          Summary
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <Typography variant="body1">{aiResponse?.summary}</Typography>
      </CardContent>
    </Card>
  );
}

export default Summary;
