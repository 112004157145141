import React from 'react';
import ReactDOM from 'react-dom/client';
import { MobxProvider } from '@servicexcelerator/ui-design-system';
import getDomain from '@utils/GetDomain';

import AuthWrapper from '@components/AuthWrapper';
import App from './App';
import RootStore from './state/RootStore';

const REDIRECT_CUSTOMER_ADVISOR =
  window.location.href.includes('customer-advisor');
const CUSTOMER_ADVISOR_DASHBAORD = `${getDomain(
  process.env.REACT_APP_API_HOST,
  '',
  false,
)}/customer-advisor/dashboard`;

const root = ReactDOM.createRoot(document.getElementById('root'));

const mobxRootStore = RootStore();

const defaultKeycloakConfig = {
  realm: '',
  client_id: '',
  authority: '',
  redirect_uri: REDIRECT_CUSTOMER_ADVISOR
    ? CUSTOMER_ADVISOR_DASHBAORD
    : getDomain(process.env.REACT_APP_API_HOST, '', false),
};

function StrictMode({ enable = false, children }) {
  if (!enable) {
    return children;
  }
  return <React.StrictMode>{children}</React.StrictMode>;
}

root.render(
  <StrictMode enable={false}>
    <MobxProvider mobxRootStore={mobxRootStore}>
      <AuthWrapper defaultKeycloakConfig={defaultKeycloakConfig}>
        <App />
      </AuthWrapper>
    </MobxProvider>
  </StrictMode>,
);
