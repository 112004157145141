import { Box } from '@mui/material';
import { LoadingEvent, Logo, Page } from '@servicexcelerator/ui-design-system';
import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { useSearchParams } from 'react-router-dom';

function Login() {
  const auth = useAuth();
  const params = useSearchParams();
  const [searchParams] = params;
  const actor = searchParams.get('actor');

  useEffect(() => {
    if (actor === 'customer') {
      window.location.href = '/customer';
    } else if (actor === 'serviceprovider') {
      auth.signinRedirect();
    }
  }, []);

  return (
    <Page>
      <Box
        sx={{ textAlign: 'center', marginTop: '100px', marginBottom: '60px' }}>
        <Logo />
      </Box>
      <Box>
        <LoadingEvent message="Logging in..." />
      </Box>
    </Page>
  );
}

export default Login;
