import { useState } from 'react';
import { nanoid } from 'nanoid';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  DYNAMIC_FORM_MODE,
  useDataProvider,
  useSnackbar,
} from '@servicexcelerator/ui-design-system';
import { Description, NoteAdd } from '@mui/icons-material';
import { Box, Button, Tab, Tabs, Typography, styled } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import NoteModal from './NoteModal';
import ListNotes from './ListNotes';
import TwilioConversationFeed from '../TwilioFeed/conversation';
import TwilioSystemMessageFeed from '../TwilioFeed/systemmessage';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`note-tabpanel-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `note-tab-${index}`,
  };
}

const ButtonStyled = styled(Button)({
  textTransform: 'none',
});

function NotesToolbar({
  serviceJobNumber,
  setSelectedNote = () => {},
  openNoteEditModal = () => undefined,
  AppModule,
}) {
  const { Components, Constants } = AppModule;
  const { useUserAccess, hasPermission } = Components;
  const { PERMISSIONS } = Constants;
  const userAccess = useUserAccess();
  const permissions = userAccess?.permissions || {};
  const canModifyServiceJob = hasPermission(
    PERMISSIONS.MODIFY_CLAIMS,
    permissions,
  );
  if (!serviceJobNumber || !canModifyServiceJob) {
    return null;
  }

  return (
    <Grid2 xs={12} textAlign="right">
      <ButtonStyled
        onClick={() => {
          setSelectedNote(null);
          openNoteEditModal(true);
        }}
        size="small"
        variant="contained"
        startIcon={<NoteAdd />}>
        <FormattedMessage id="ADD_NOTE" defaultMessage="Add Note" />
      </ButtonStyled>
    </Grid2>
  );
}

function NotesAttachment({ serviceJobData, formMode, AppModule }) {
  const { Components, Constants } = AppModule;
  const { useUserAccess, hasPermission } = Components;
  const { COMPANY_TYPE, PERMISSIONS, SERVICE_JOB_STATUS } = Constants;

  const provider =
    process.env.NODE_ENV === 'development' ? 'serviceJob' : 'default';

  const { formatMessage } = useIntl();
  const [selectedNote, setSelectedNote] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [value, setValue] = useState(0);
  const [listId, setListId] = useState(nanoid(4));
  const { deleteOne } = useDataProvider(provider);
  const snackbar = useSnackbar();
  const userAccess = useUserAccess();
  const permissions = userAccess?.permissions || {};

  // TODO: Should this be can modify servicejob?
  const canModifyServiceJob = hasPermission(
    PERMISSIONS.MODIFY_CLAIMS,
    permissions,
  );

  let canModifyNote = false;
  if (
    canModifyServiceJob &&
    userAccess.companyType === COMPANY_TYPE.SERVICE_ADMINISTRATOR
  ) {
    canModifyNote = true;
  } else if (
    canModifyServiceJob &&
    serviceJobData?.serviceJobStatus &&
    serviceJobData?.serviceJobStatus.key !== SERVICE_JOB_STATUS.COM
  ) {
    canModifyNote = true;
  }

  const onNoteChanged = () => {
    setListId(nanoid(4));
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onDeleteNote = async note => {
    if (note?.id) {
      const result = await deleteOne(
        `/servicejob/v1/servicejob/${serviceJobData.serviceJobNumber}/note/${note.id}`,
      );
      if (result?.data) {
        snackbar.showMessage(
          formatMessage({
            id: 'NOTE_DELETED_SUCCESSFULLY',
            defaultMessage: 'Note was deleted successfully',
          }),
        );
        setListId(nanoid(4));
      } else {
        snackbar.showMessage({
          type: 'error',
          data: formatMessage({
            id: 'NOTE_COULD_NOT_BE_DELETED',
            defaultMessage: 'Note could not be deleted',
          }),
        });
      }
    }
  };

  return (
    <Grid2 container>
      <NotesToolbar
        formMode={formMode}
        serviceJobNumber={serviceJobData?.serviceJobNumber}
        setSelectedNote={setSelectedNote}
        openNoteEditModal={data => setOpenEditModal(data)}
        AppModule={AppModule}
      />
      {formMode === DYNAMIC_FORM_MODE.CREATE ? (
        <Grid2 xs>
          <Typography>
            <FormattedMessage
              id="SAVE_SERVICE_JOB_TO_ADD_NOTES"
              defaultMessage="Please save the job to add notes"
            />
          </Typography>
        </Grid2>
      ) : (
        <Grid2 xs={12}>
          <Box>
            <Tabs value={value} variant="scrollable" onChange={handleChange}>
              <Tab
                label={formatMessage({
                  id: 'NOTES_FEED',
                  defaultMessage: 'Notes',
                })}
                {...a11yProps(0)}
                value={0}
              />
              <Tab
                label={formatMessage({
                  id: 'SERVICE_ADVISOR_CONVERSATION',
                  defaultMessage: 'Service Advisor Conversation',
                })}
                {...a11yProps(1)}
                value={1}
              />
              <Tab
                label={formatMessage({
                  id: 'SERVICE_ADVISOR_MESSAGES',
                  defaultMessage: 'Service Advisor Messages',
                })}
                {...a11yProps(2)}
                value={2}
              />
            </Tabs>
          </Box>

          <CustomTabPanel value={value} index={0}>
            <ListNotes
              key={listId}
              onDeleteNote={noteToDelete => onDeleteNote(noteToDelete)}
              onNoteSelected={note => {
                setSelectedNote(note);
                setOpenEditModal(true);
              }}
              serviceJobNumber={serviceJobData?.serviceJobNumber}
              canModifyNote={canModifyNote}
              AppModule={AppModule}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <TwilioConversationFeed
              serviceJobData={serviceJobData}
              AppModule={AppModule}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <TwilioSystemMessageFeed
              serviceJobData={serviceJobData}
              AppModule={AppModule}
            />
          </CustomTabPanel>
        </Grid2>
      )}
      {openEditModal && (
        <NoteModal
          NoteIcon={Description}
          note={selectedNote}
          serviceJobNumber={serviceJobData?.serviceJobNumber}
          setOpen={setOpenEditModal}
          onNoteChanged={onNoteChanged}
          AppModule={AppModule}
        />
      )}
    </Grid2>
  );
}

export default NotesAttachment;
